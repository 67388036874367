<template ref="appContainer">
	<v-row class="app-containerHeader line" :class="appContainerHeaderClass">
		<v-col class="py-0 col-7 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-xl-10"></v-col>

		<v-col class="py-0 col-7 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 mt-3">
			<button class="header_info_btn" @click="enviar" target="_blank"><i class="v-icon notranslate fa-kit fa-line-help"></i></button>
			<!--
				<v-btn class="header_info_btn" @click="enviar" target="_blank"
					>{{ $t('appcontainerheader.btntext') }}<i class="v-icon notranslate fa-kit fa-line-help"></i
				></v-btn>
					-->
		</v-col>

		<!--
container d-flex flex-wrap pui-toolbar pt-0 pb-0 container--fluid pui-toolbar--desktop pui-toolbar--menu-closed
container d-flex flex-wrap pui-toolbar pt-0 pb-0 container--fluid pui-toolbar--desktop pui-toolbar--menu-closed pui-toolbar--menu-secondary-opened
				class="col-7 col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8"
			<v-dialog v-model="dialog" max-width="300px">
				<v-card>
					<v-card-title>{{ $t('appcontainerheader.title') }}</v-card-title>
					<v-card-text>
						<div>

							<a :href="`${publicPath}notificacion escala residuos.pdf`" download="notificacion escala residuos.pdf" target="_blank">{{
								$t('appcontainerheader.link1')
							}}</a>


							<a @click="enviar" target="_blank">{{ $t('appcontainerheader.link1') }}</a>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="closeModal">{{ $t('appcontainerheader.close') }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			-->
	</v-row>
</template>
<style lang="postcss" scoped>
.app-containerHeader {
	width: calc(100% - 64px + 24px); /*PuiMenuIconBar*/
	&--menu-opened {
		width: calc(100% - 304px + 24px); /*VNavigationDrawer*/
	}
}
</style>
<script>
export default {
	name: 'AppContainerHeader',
	data() {
		return {
			dialog: false,
			publicPath: process.env.BASE_URL
		};
	},
	methods: {
		openModal() {
			this.dialog = true;
		},
		closeModal() {
			this.dialog = false;
		},
		enviar() {
			this.$router.push('/helptable');
			this.dialog = false;
		}
	},
	computed: {
		expandMenu() {
			return this.$store.state.menu.expandMenu;
		},
		secondMenuShowed() {
			return this.$store.state.menu.secondMenuShowed;
		},
		appContainerHeaderClass() {
			return {
				'app-containerHeader--menu-opened': this.expandMenu || this.secondMenuShowed
			};
		}
	},
	mounted() {
		// header height calculation after mount and update
		this.$puiEvents.$emit('onAppContainerHeaderUpdate');
	},
	updated() {
		this.$puiEvents.$emit('onAppContainerHeaderUpdate');
	}
};
</script>
